import React, { FC, forwardRef, ReactNode, useRef, useState } from 'react';
import {
  Text,
  TextTypography,
  Button,
  ButtonPriority,
  DatePickerInput,
  ButtonSize,
  ThreeDotsLoader,
  Divider,
  DatePickerInputLocale,
} from 'wix-ui-tpa/cssVars';
import { Plan, StartType } from '@wix/ambassador-pricing-plans-v3-plan/types';
import type { FormValues, FormError, FormViewerHandle } from '@wix/form-viewer/widget';
import { FormViewer } from '@wix/form-viewer/widget';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useLocale } from '../../../hooks/useLocale';
import { PLAN_CUSTOMIZATION_HOOKS } from '../hooks';
import { st, classes } from './PlanCustomizationWidget.st.css';
import { PlanPreview } from './PlanPreview/PlanPreview';

export interface PlanCustomization {
  startDate: Date | null;
  formValues: FormValues | null;
}

export interface PlanCustomizationProps {
  status: 'PAGE_LOADED' | 'PAGE_LOADING' | 'CHECKOUT_LOADING' | 'ERROR';
  plan: Plan;
  shouldRequestLogin: boolean;
  proceedToPayment: (customization: PlanCustomization) => Promise<void>;
}

const ProductPageWidget: React.FC<PlanCustomizationProps> = (props) => {
  if (props.status === 'PAGE_LOADING') {
    // TODO: Add loader
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <header>
          <Text tagName="h1" typography={TextTypography.largeTitle}>
            Complete the following details
          </Text>
        </header>
        <Divider className={classes.headerDivider} />
        <main>
          <ProductCustomizationForm {...props} />
        </main>
      </div>
      <aside className={classes.previewContainer}>
        <PlanPreview plan={props.plan} />
      </aside>
    </div>
  );
};

const ProductCustomizationForm: FC<PlanCustomizationProps> = ({
  plan,
  status,
  proceedToPayment,
  shouldRequestLogin,
}) => {
  const formViewerRef = useRef<FormViewerHandle>(null);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date());

  const isCheckoutLoading = status === 'CHECKOUT_LOADING';
  const allowFutureStartDate = plan.pricingVariants?.[0].billingTerms?.startType === StartType.CUSTOM;
  const hasConnectedForm = !!plan.formId;

  const onContinueClick = async () => {
    // TODO: What to do if (hasConnectedForm && !formViewerRef.current) ?
    if (hasConnectedForm && formViewerRef.current) {
      const isValid = await formViewerRef.current?.validate();
      if (!isValid) {
        return;
      }
    }

    proceedToPayment({
      startDate: allowFutureStartDate ? customStartDate : null,
      formValues: hasConnectedForm ? formValues : null,
    });
  };

  return (
    <div>
      {allowFutureStartDate && <PlanStartDate value={customStartDate} onChange={(date) => setCustomStartDate(date)} />}
      {plan.formId && (
        <AdditionalDetails plan={plan} formValues={formValues} setFormValues={setFormValues} ref={formViewerRef} />
      )}
      <ContinueButton loading={isCheckoutLoading} onClick={onContinueClick}>
        {shouldRequestLogin ? 'Log in to proceed' : 'Proceed to payment'}
      </ContinueButton>
    </div>
  );
};

const AdditionalDetails = forwardRef<
  FormViewerHandle,
  { plan: Plan; formValues: FormValues; setFormValues: (values: FormValues) => void }
>(({ plan, formValues, setFormValues }, ref) => {
  const { i18n } = useTranslation();
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  return (
    <FormViewer
      ref={ref}
      i18n={i18n}
      onChange={(values) => setFormValues(values)}
      values={formValues}
      onValidate={(errors) => setFormErrors(errors)}
      errors={formErrors}
      formId={plan.formId!}
    />
  );
});

const PlanStartDate: FC<{ value: Date; onChange: (value: Date) => void }> = ({ value, onChange }) => {
  const { locale } = useLocale();
  return (
    <DatePickerInput
      className={classes.datePicker}
      value={value}
      onChange={(_value) => onChange(new Date(_value))}
      label="Choose a date"
      excludePastDates
      removeClearButton
      locale={locale as DatePickerInputLocale}
      data-hook={PLAN_CUSTOMIZATION_HOOKS.DATE_PICKER}
    />
  );
};

const ContinueButton: FC<{ loading: boolean; children: ReactNode; onClick: () => void }> = ({
  loading,
  onClick,
  children,
}) => (
  <Button
    size={ButtonSize.large}
    className={st(classes.ctaButton, { loading })}
    fullWidth
    upgrade
    priority={ButtonPriority.primary}
    onClick={onClick}
    disabled={loading}
  >
    <span className={classes.label}>{children}</span>
    {loading && (
      <div className={classes.loaderContainer}>
        <ThreeDotsLoader className={classes.loader} />
      </div>
    )}
  </Button>
);

export default ProductPageWidget;
